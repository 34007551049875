<template>
  <!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
  <div class="section">
    <!-- <h2>{{state.curName}}</h2> -->
    <!-- <div class="time"><span style="margin-right:.5rem">2022-03-26</span><span>浏览量：7589</span></div> -->
    <Section :section="state.section" :topIsShow="state.topIsShow" v-if="state.section.leftNav != ''">  
      <!-- 数字乡村云平台 -->
      <template v-slot:17>
        <p class="animate__animated animate__fadeInUp wow" >{{state.info.list[0].describe}}</p>  
        <img :src="item.images" v-for="(item,index) in state.info.list[0].imgs"  :key="index" class="animate__animated animate__fadeInUp wow" />
      </template>



    </Section>
  </div>
  <!-- 返回顶部 -->
  <img src="@/assets/images/top.png" class="top" v-if="state.topIsShow" @click="backTop" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg1" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgRight logoBg2" />
  <img src="@/assets/images/logoBg.png" class="logoSize logoBgLeft logoBg3" />
</template>

<script>
import { useRoute } from 'vue-router'
import {onMounted,watchEffect,reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
// import { getYewDetail} from "../../api/request"
import Section from "./components/sectionNav.vue"
import { getSource,getbannerData} from "../../api/request"
export default {
  name: "Business",
  components:{
    Banner,
    Section
  },
  setup() {
    const route = useRoute()
    onMounted(()=>{
        var wow = new WOW({
        boxClass: 'wow',    //需要执行动画元素的Class
        animateClass: 'animated',    //animation.css动画的Class
        offset: 100,    //距离可视区域多少开始执行动画
        mobile: true,    //是否在移动设备执行动画
        live: true    //异步加载的内容是否有效
        })
        wow.init()
        getBanner()
        window.scrollTo(0,0);
      })
        
    let state = reactive({
      bannerArr:[],
      curId:0,
      curName:"",
      info:"",
      section:{
        title:"",
        leftNav:[
        ],
        isShow:false
      },
      scrollHeight:0,
      topIsShow:false 
    })
    watchEffect(()=>{
        state.curId = route.query.id
        getData(state.curId)
        // window.scrollTo(0,0);
         window.addEventListener('scroll',scrollY,true)
        // scrollY()
        
    })
    function getBanner(){
      getbannerData({id:37}).then((res)=>{
        // console.log(res)
        if(res.code == 200){
          state.bannerArr = res.data
        }
      })
    }
    function getData(id){
      getSource({id:id}).then((res)=>{
        // console.log(id)
        console.log(res)
        state.section.leftNav = res.data.title
        state.section.title = res.data.top_t.name
        state.info =  res.data
        state.section.isShow = true
        // console.log(state.info)
      })
    }
    // 滚动条距离顶部距离
    function scrollY(){
      state.scrollHeight = window.pageYOffset
      if(state.scrollHeight > 500){
        state.topIsShow = true
      }else{
        state.topIsShow = false
      }
    }
    function backTop(){
        // window.scrollTo(0,0);
      let timer = setInterval(() => {
        let ispeed = Math.floor(-state.scrollHeight / 5)
        document.documentElement.scrollTop = document.body.scrollTop = state.scrollHeight + ispeed
        if (state.scrollHeight === 0) {
          clearInterval(timer)
        }
      }, 16)
    }
    return {
      state,
      getData,
      getBanner,
      scrollY,
      backTop
    };
  },
};
</script>

<style lang="scss" scoped>
  .section{
    padding-bottom: 1rem;
    p{
      font-size: .16rem;
      color: #555;
      line-height: .3rem;
      text-indent: .3rem;
    }
    img{
      margin: .5rem auto ;
      max-width: 100%;
      display: block;
    }
    h5{
      font-size: .18rem;
      color: #555;
      line-height: 1rem;
      text-align: center;
    }
    h6{
        margin-top: -.2rem;
        font-size: .18rem;
        color: #555;
        text-align: center;
        font-weight: normal;
      }

    .style1{
      display: flex;
      justify-content: space-between;
      img{
        margin: 0;
      }
      .leftImg{
        width: 2.51rem;
        height: 5.16rem;
        margin-right:.6rem
      }
      ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: space-between;
        li{
          display: flex;
          margin: .3rem 0;
          img{
            width: .9rem;
            height: .9rem;
          }
          span{
            display: block;
            font-size: .16rem;
            color: #555555;
            text-indent: .24rem;
            line-height: .3rem;
            margin-left: .2rem;
          }
        }
        li:nth-child(2){
          img{
            animation-delay: .2s;
          }
          span{
            animation-delay: .5s;
          } 
        }
        li:nth-child(3){
          img{
            animation-delay: .8s;
          }
          span{
            animation-delay: 1s;
          }
        }
      }
    }
    }

  
  .top{
    position: fixed;
    right: 0;
    bottom: 1rem;
    width: .5rem;
    height: .5rem;
    cursor: pointer;
    z-index: 9999;
  }
  .logoSize{
    width: 1.065rem;
    height: 1.81rem;
  }
  .logoBgLeft{
    position: absolute;
    left: 0;
  }
  .logoBgRight{
    transform:rotate(180deg);
    position: absolute;
    right: 0;
  }
  .logoBg1{
    top: 10rem;
  }
  .logoBg2{
    top: 20rem;
  }
  .logoBg3{
    top: 30rem;
  }
  .logoBg4{
    top: 40rem;
  }
  .logoBg5{
    top: 50rem;
  }
</style>
<template>
    <div class="section">
        <div class="leftNav animate__animated animate__bounceInDown wow">
        <h2>业务领域</h2>
        <h3>{{section.title}}</h3>
        <ul>
          <li class="animate__animated animate__backInUp wow" :class="state.curNavId == item.id ? 'cur' : ''" v-for="(item,index) in section.leftNav" :key="index" @click="navClick(item.id)">{{item.name}}</li>
        </ul>
      </div>
      <div class="rightInfo">
          <div class="listItem" v-for="(item1,index) in section.leftNav" :key="index" :id="item1.id"  @scroll="scrollEvent(item.id)">
              <h4 :class="state.curNavId == item1.id ? 'cur' : ''" class=" animate__animated animate__fadeInUp wow">{{item1.name}}</h4>
              <slot :name="item1.id"></slot>
          </div>
        
      </div>
    </div>
</template>
<style  lang="scss" scoped>
.section{
    width: 12rem;
    margin: 0 auto;
    display: flex;
    .leftNav{
        width: 3.6rem;
        height: 5.6rem;
        flex-shrink: 0;
        border-left:.08rem solid #EA7D13 ;
        margin-top: -80px;
        position: relative;
        z-index: 998;
        background: #fff;
        padding-left: .5rem;
        box-sizing: border-box;
        h2{
        line-height: 80px;
        font-size: .3rem;
        border-right:.08rem solid #EA7D13 ;
        }
        h3{
        font-size: .26rem;
        font-weight: normal;
        margin: .2rem 0;
        }
        ul{
        margin: .3rem  0 .2rem;
        font-size: .16rem;
        margin-right: 1rem;
        li{
            border-bottom: 1px solid #E6E6E6;
            line-height: .3rem;
            padding: .15rem 0;
            color: #777777;
            cursor: pointer;
            animation-delay: .2s;
        }
        li.cur{
            border-bottom: 1px solid #EA7D13;
            color: #EA7D13;
        }
        }
    }
    .rightInfo{
        flex: 1;
        margin-left: .5rem;
        .listItem{
            animation-delay: .5s;
            h4{
                border-bottom: 1px solid #E6E6E6;
                font-size: .22rem;
                line-height: .8rem;
                font-weight: normal;
                margin-bottom: .5rem;
            }
            h4.cur{
                border-bottom: 1px solid #EA7D13;
                color: #EA7D13;
            }
        }
    }
}
</style>
<script>
import {onMounted,reactive, watchEffect} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:["section",'topIsShow'],
    setup(props){
        onMounted(()=>{
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 50,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            // console.log(props)
                        
        })
        let state = reactive({
            curNavId:0
        })
        function navClick(id){
            document.getElementById(id).scrollIntoView();
            state.curNavId = id
        }
        watchEffect(()=>{
            if(!props.topIsShow){
                state.curNavId = props.section.leftNav[0].id
            }
        })
        return{
            state,
            navClick
        }
    }
}
</script>